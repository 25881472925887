import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { Layout} from "antd"

const { Footer, Content } = Layout


const Header = () => {
    return (
      <nav className="navbar navbar-fixed-top navbar-has-shadow">
        <div className="container">
          <div className="navbar-header">
              <a className="navbar-brand" href="https://datafold.com">
                <img
                src="/assets/images/elements/datafold_icon+logo.png"
                className="navbar-brand__img"
                alt=""
              />
              </a>
          </div>
        </div>
      </nav>
    )
}

const Container = ({ defKey, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Header />
      <Content className="calc">
        <div className="calc_container">{children}</div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
       <div className="copyright"> © {new Date().getFullYear()} Datafold</div>
      </Footer>
    </Layout>
  )
}
Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
